import Box from '@mui/material/Box';
import { Avatar, Stack, TextField, } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState, useMemo, useEffect } from 'react';
import OZInput from '../../../components/admin/common/OZInput';
import './Adminsettings.css';
import { Button, Grid, IconButton, DialogActions } from '@mui/material';
import { Formik } from 'formik';
import { forgotPassword, getSettingsListByCode, getUserLoggedIn, settingsSave, updatePasswordAdmin, } from "../../../services/api-service";
import * as yup from 'yup';
import OZDialog from '../../../components/admin/common/OZDialog';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import './otpVerification.css';
import { getUserRoles } from '../../../services/auth-service';
import OZSelect from '../../../components/admin/common/OZSelect';
import { stringAvatar } from "../../../components/admin/common/Avatar";
import { checkRoles } from '../../../utils/util';
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';




export default function AccountSettings(props) {

  const [isProfileEdit, setProfileEdit] = useState(false);
  const [isSystemSettingEdit, setSystemSettingEditState] = useState(false)
  const [isEdit, setEditState] = useState(false);

  const [userItems, setUserItems] = useState({});
  const [UserDetails, setUserDetails] = useState([]);
  const [textEditModes, setTextEditMode] = useState({});

  useEffect(() => {
    getuserLogged();
    settingsListByCode();
  }, []);
  const displayCRMmode = [{ screenPopMode: 1, CRMType: 'Embed' }, { screenPopMode: 2, CRMType: 'Popup' }, { screenPopMode: 3, CRMType: "Open in New Tab" }]

  if (userItems) {
    displayCRMmode.forEach(item => {
      if (item.screenPopMode === userItems.screenPopMode) {
        userItems.screenPopMode = item;
      }
    })
  };

  const validationSchema = useMemo(() => (yup.object({
    firstName: yup
      .string("Enter First Name")
      .test("maxDigits",
        "First Name should be between 3 to 50 alphanumeric characters long and allows special characters like _",
        (number) => String(number).length >= 3 && String(number).length <= 50)
      .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "First Name should be between 3 to 50 alphanumeric characters long and allows special characters like _")
      .required('First Name is required'),
    lastName: yup
      .string("Enter Last Name")
      .test("maxDigits",
        "Last Name should be between 3 to 50 alphanumeric characters long and allows special characters like _",
        (number) => String(number).length >= 3 && String(number).length <= 50)
      .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Last Name should be between 3 to 50 alphanumeric characters long and allows special characters like _")
      .required('Last Name is required'),
    username: yup
      .string('Enter Username')
      .test("maxDigits",
        "Username should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-",
        (number) => String(number).length >= 3 && String(number).length <= 50)
      .matches(/^([A-Za-z\d]((\.|@|_|\-)?[A-Za-z\d])+)$/, "Username should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
      .required('Username is required'),
    email: yup
      .string("Enter Email")
      .email('enter valid email')
      .test("maxDigits",
        "E-Mail should be a valid email.",
        (number) => String(number).length >= 5 && String(number).length <= 50)
      .required('Email is required'),
    phoneNumber: yup
      .number("Enter PhoneNumber")
      .test("maxDigits",
        "Phone Number should be between 10 to 16 digits long and allows prefix +",
        (number) => String(number).length >= 10 && String(number).length <= 16)
      .typeError('The value must be a number')
      .integer('The value must be a number')
      .required('PhoneNumber is required'),
  })), []);


  const SettingNameStyle = {
    height: '21px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#212121',
    paddingTop: '16px',
    flexGrow: 1,
    alignSelf: 'center'
  }

  async function adminSettingsave(data) {
    try {
      settingsSave(data).then((resp) => {
        if (resp.Status === "Success") {
          showSuccessNotification(`Setting Parameter(${data.parameter.name}) has been updated successfully with value (${data.parameterValue})`)
          settingsListByCode();
        }
        else {
          showErrorNotification(resp.Message);
        }
      }).catch((e) => {
        showErrorNotification("Not able to update Setting, Please contact to Admin")
      });
    } catch (e) {
      showErrorNotification("Not able to update Setting, Please contact to Admin");
    }
  };

  async function getuserLogged() {
    try {
      getUserLoggedIn().then((resp) => {
        let data = resp.Data[0]
        setUserItems(data);
        initData.firstName = data.firstName
        initData.lastName = data.lastName
        initData.username = data.username
        initData.email = data.email
        initData.phoneNumber = data.phoneNumber
        initData.apiKey = data.apiKey
        systemSettingFields.callbackUrl = data.callbackUrl
        systemSettingFields.screenPopMode = userItems.screenPopMode?.CRMType
      })
        .catch((e) => {
          setUserItems({});
        });
    } catch (e) {
      setUserItems({});
    }
  };

  const handleEditTextField = (settingName, value) => {
    setTextEditMode(prev => ({ ...prev, [settingName]: value }))
  };

  async function settingsListByCode() {
    let settingName = 'USER_CREATION_LIMIT,AGENT_CREATION_LIMIT,AGENT_LOGIN_LIMIT,FWP_CREATION_LIMIT';
    try {
      getSettingsListByCode({ isAdmin: "true", settingsCode: settingName }).then((resp) => {
        if (resp?.Status === 'Success') {
          let data = resp.Data;
          setUserDetails(data);
        } else {
          showErrorNotification(resp?.Message);
          setUserDetails([]);
        }
      }).catch((e) => {
        setUserDetails([]);
      });
    } catch (e) {
      setUserDetails([]);
    }
  };

  function saveSettings(setting, newValue) {
    try {
      let body = { "parameter": { "id": setting.ParameterID, "code": setting.ParameterCode, "name": setting.ParameterName }, "parameterValue": newValue }
      switch (setting.InputType) {
        case "textfield":
          handleEditTextField(setting.ParameterName, false)
          break;
        default:
          showErrorNotification('Not valid setting input', setting.InputType);
      }
      adminSettingsave(body);
    } catch (e) {
      showErrorNotification('error while saving settings')
    }
  }


  async function updateSystemSettingsFields(data) {
    try {
      updatePasswordAdmin(data).then((resp) => {
        if (resp.Status === "Success") {
          showSuccessNotification(resp.Message);
          getuserLogged();
          setSystemSettingEditState(false);
          setEditState(false)
        }
        else {
          showWarningNotification(resp.Message);
        }
      }).catch((e) => {
        showErrorNotification("error in update")
      });
    }
    catch (e) {
      showErrorNotification("error in update");
    }

  }


  async function updateAdminDetails(data) {
    try {
      if (isProfileEdit) {
        updatePasswordAdmin(data).then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification("Your profile has been updated successfully.");
            getuserLogged();
            setProfileEdit(false)
          }
          else {
            showErrorNotification(typeof resp.Message === 'string' ? resp.Message : JSON.stringify(resp.Message));
          }
        })
          .catch((e) => {
            showErrorNotification("error in update");
          });
      } else {
        setProfileEdit(true)
      }

    } catch (e) {
      showErrorNotification("error in update");
    }
  };


  const [showDialog, setDialog] = useState(false);
  let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '44px', fontSize: '14px', fontWeight: 400 }), [])
  let SystemSettingsbuttonStyle = useMemo(() => ({ borderRadius: '8px', height: '27px', fontSize: '14px', width: '76px', fontWeight: 400 }), [])





  const closeAdminEditForm = () => {
    setDialog(false);
  }



  let initData = { firstName: '', lastName: '', username: '', email: '', phoneNumber: '', apiKey: '' };
  let systemSettingFields = { callbackUrl: userItems.callbackUrl, screenPopMode: userItems.screenPopMode?.CRMType }


  const ChangePasswordAdmin = (props) => {
    const [open, setOpen] = useState(true);
    /* const [changePassword, setChangePassword] = useState([]); */
    const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false)
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(30);
    const [newPasswordDialog, setNewPasswordDialog] = useState(false);

    // useEffect(() => {
    //   getuserLoggedInLocal();
    // })


    // async function getuserLoggedInLocal() {
    //   try {
    //     getUserLoggedIn().then((resp) => {
    //       setChangePassword(resp.Data[0]);
    //     })
    //       .catch((e) => {
    //         setChangePassword({});
    //       });
    //   } catch (e) {
    //     setChangePassword();
    //   }
    // };



    async function getChangePassword(data) {
      try {
        updatePasswordAdmin(data).then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(resp.Message);
            props.onClose();
          }
          else {
            showWarningNotification(resp.Message);
          }
        })
          .catch((e) => {
            showErrorNotification("error in password update");
          });
      } catch (e) {
        showErrorNotification("error in password update");
      }
    };

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '44px', fontSize: '14px', fontWeight: 400, backgroundColor: '#3D8BF8', marginLeft: '40px' }), [])


    const FormSchema = yup.object().shape({
      // password: yup
      //   .string()
      //   .min(8, 'Use a password at least 8 characters long with both letters and numbers.')
      //   .matches(/[0-9]/, 'Password requires a number')
      //   .matches(/[a-z]/, 'Password requires a lowercase letter')
      //   .matches(/[A-Z]/, 'Password requires an uppercase letter')
      //   .matches(/[^\w]/, 'Password requires a symbol'),
      oldpassword: yup
        .string()
        .required("Old Password is required"),
      password: yup
        .string("Enter Password")
        .required('Password is required')
        .test("maxDigits",
          "The password must not contain spaces. The password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters.",
          (number) => String(number).length >= 7 && String(number).length <= 50)
        .matches(/^((?!.*[\s])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-_!@#$%^&*()+{}[\]:;<>,.?~]).{7,50})$/, "The Password must not contain spaces. The Password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters."),
      // confirmpassword: yup
      //   .string()
      //   .oneOf([yup.ref('password'), null], 'Must match with New Password'),
      confirmpassword: yup
        .string("Enter Confirm Password")
        .required('Confirm Password is required.')
        .oneOf([yup.ref('password'), null], 'Confirm Password should be the same.'),
    });


    let initData = props.data || {
      oldpassword: '',
      password: '',
      confirmpassword: ''
    };



    const handleClose = () => {
      setDialog(false);
      setForgotPasswordDialog(false);
      setNewPasswordDialog(false);
    };

    const onClickPreviousButton = () => {
      setForgotPasswordDialog(false);
    }

    const handleCloseNewPasswordDialog = () => {
      setDialog(false);
      setForgotPasswordDialog(false);
      setNewPasswordDialog(false);
    }

    const onClickPreviousButtonNewDialog = () => {
      setForgotPasswordDialog(forgotPasswordDialog);
      setNewPasswordDialog(!newPasswordDialog);
    }

    // const onClickForgotPassword = () => {
    //   setForgotPasswordDialog(!forgotPasswordDialog);
    // }

    const onClickForgotPassword = async (event: any, user: any) => {
      event.preventDefault();
      try {
        await forgotPassword({ user }).then(async (resp) => {
          if (resp) {
            if (resp?.status === "error") {
              showErrorNotification(resp?.message);
              return;
            } else if (resp?.status === "success") {
              showSuccessNotification(resp?.message);
              return;
            }
          }
        }).catch((e) => {
          showErrorNotification('Please Try again Later');
          return;
        })
      }
      catch (e) {
        showErrorNotification('Please Try again Later');
        return;
      }
    };

    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          }
          else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [seconds]);

    const handleChange = (element, index) => {
      if (isNaN(element.value)) return false;

      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    };

    const resendOTP = () => {
      setMinutes(1);
      setSeconds(50);
    };

    const SubmitOTP = (e) => {
      showSuccessNotification("Entered OTP is " + otp.join(""));
      setNewPasswordDialog(true);
    }

    return (<div className='admin-change-password-form'>
      {!forgotPasswordDialog &&
        <OZDialog
          title="Change Password"
          hideActions={true}
          open={open}
          onClose={closeAdminEditForm}
        >
          <Formik
            initialValues={initData}
            validationSchema={FormSchema}
            onSubmit={(data) => {
              getChangePassword(data);
            }}>
            {
              props => (
                <form onSubmit={props.handleSubmit} id='admin-change-password-form' style={{
                  maxHeight: '67vh',
                  width: '310px'
                }}>
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Typography sx={{
                        fontfamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: '#000000',
                        paddingBottom: '8px',
                      }} flexGrow={1} alignSelf='center'>Enter Old Password</Typography>
                      <OZInput type="password" name="oldpassword"
                        placeholder="Enter Password" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Typography sx={{
                        fontfamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: '#000000',
                        paddingBottom: '8px',
                        marginTop: '12px'
                      }} flexGrow={1} alignSelf='center'>Enter New Password</Typography>
                      <OZInput type="password" name="password"
                        placeholder="Enter New Password" />
                    </Grid>
                    <span style={{ color: "#7A7A7A", fontSize: '12px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', opacity: '0.6' }}>Use a password at least 7 characters long with both letters and numbers.</span>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Typography sx={{
                        fontfamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: '#000000',
                        paddingBottom: '8px',
                        marginTop: '12px'
                      }} flexGrow={1} alignSelf='center'>Confirm New Password</Typography>
                      <OZInput type="password" name="confirmpassword"
                        placeholder="Enter New Password" />
                      <Stack direction='row' spacing={4} sx={{ marginTop: '24px', marginBottom: '20px' }}>
                        <Button onClick={(e) => onClickForgotPassword(e, userItems?.username)} onChange={!props.open} sx={{ fontWeight: 400 }}>
                          Forgot Password?
                        </Button>
                        <Button autoFocus variant='contained' sx={{ ...buttonStyle, fontWeight: 400, paddingLeft: '22px' }} type='submit'
                          // onClick={() => getChangePassword(changePassword)}
                          form="admin-change-password-form"
                        >
                          Change Password
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              )
            }
          </Formik>
        </OZDialog>
      }

      {forgotPasswordDialog &&
        <Dialog
          open={props.open}
          onClose={handleClose}
        >
          <Stack direction={'row'}>
            <Stack direction={'row'} sx={{ marginLeft: '20px' }}>
              <IconButton
                onClick={onClickPreviousButton}>
                <img
                  src={`${process.env.PUBLIC_URL}/icons/previousButton.svg`}
                  alt="previousButton"
                ></img>
              </IconButton>
              <DialogTitle sx={{ fontSize: '18px', paddingLeft: '8px' }}>Forgot Password</DialogTitle>
            </Stack>
            {handleClose ? (
              <IconButton
                style={{ marginLeft: '40px' }}
                aria-label="close"
                onClick={handleClose}
                sx={{
                  color: '#99A0A8',
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/icons/close.svg`} alt='x' ></img>
              </IconButton>
            ) : null}
          </Stack>
          <div style={{ alignSelf: 'center' }}>
            <img
              src={`${process.env.PUBLIC_URL}/icons/forgotPassword.svg`}
              alt="forgotpasswordimage"
            ></img>
          </div>
          <div style={{ marginLeft: '44px', marginRight: '44px' }}>
            <Typography sx={{ fontSize: '18px' }}>Please enter 4 digit code to</Typography>
            <span style={{ color: "#7A7A7A", fontSize: '18px' }}>{userItems.email}</span>
          </div>
          <>
            <div className="col-text-center">
              <div className="otp-input-group">
                {otp.map((data, index) => {
                  return (
                    <input
                      className='otp-input'
                      size='small'
                      type="text"
                      name="otp"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={e => handleChange(e.target, index)}
                      onFocus={e => e.target.select()}
                    />
                  );
                })}
              </div>
              <div className="countdown-text" style={{ display: 'flex', direction: 'row', marginBottom: '24px' }} >
                {seconds > 0 || minutes > 0 ? (
                  <>
                    <Typography sx={{ marginLeft: '20px', marginTop: '10px' }}>
                      Resend OTP in
                    </Typography>
                    <Typography sx={{ color: '#008DFF', marginLeft: '5px', marginTop: '10px' }}>
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </Typography>
                  </>
                ) : (
                  <Button
                    disabled={seconds > 0 || minutes > 0}
                    onClick={resendOTP}
                    sx={{ borderRadius: '8px', height: '44px', fontSize: '14px', fontWeight: 400, paddingBottom: '10px', marginLeft: '20px' }}
                  >
                    Resend Code
                  </Button>
                )}
                <Button variant='contained' onClick={SubmitOTP} type='submit' sx={buttonStyle}>Submit</Button>
              </div>
            </div>
          </>
        </Dialog>
      }
      {newPasswordDialog && <Dialog
        height='150px'
        open={props.open}
        onClose={handleClose}
      >
        <Stack direction={'row'}>
          <Stack direction={'row'} sx={{ marginLeft: '20px' }}>
            <IconButton
              onClick={onClickPreviousButtonNewDialog}>
              <img
                src={`${process.env.PUBLIC_URL}/icons/previousButton.svg`}
                alt="previousButton"
              ></img>
            </IconButton>
            <DialogTitle sx={{ fontSize: '18px', paddingLeft: '8px' }}>Forgot Password</DialogTitle>
          </Stack>
          {handleCloseNewPasswordDialog ? (
            <IconButton
              style={{ marginLeft: '80px' }}
              aria-label="close"
              onClick={handleCloseNewPasswordDialog}
              sx={{
                color: '#99A0A8',
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/icons/close.svg`} alt='x' ></img>
            </IconButton>
          ) : null}
        </Stack>
        <div style={{ alignSelf: 'center' }}>
          <img
            src={`${process.env.PUBLIC_URL}/icons/forgotPassword.svg`}
            alt="forgotpasswordimage"
          ></img>
        </div>
        <div>
          <Formik
            initialValues={initData}
            validationSchema={FormSchema}
            onSubmit={(data) => {
              getChangePassword(data);
            }}>
            {
              props => (
                <form onSubmit={props.handleSubmit} id='admin-change-password-form2' style={{
                  width: '350px'
                }}>
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Typography sx={{
                        fontfamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: '#000000',
                        paddingBottom: '8px',
                        marginTop: '12px',
                        marginLeft: '20px',
                        marginRight: '20px'
                      }} flexGrow={1} alignSelf='center'>Enter New Password</Typography>

                      {/* <TextField name="password" size='small' type="password" placeholder='Enter New Password' sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '4px',
                          backgroundColor: '#ffff',
                          fontSize: '14px',
                          height: '44px',
                          marginRight: '20px',
                          marginTop: '6px',
                          marginBottom: '6px',
                          width: '310px',
                          marginLeft: '20px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #D6D6D7'
                        },
                        '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                          backgroundColor: '#D6D6D7',
                          border: '1px solid #D6D6D7'
                        }
                      }} /> */}
                    </Grid>
                    <div style={{ width: '310px', marginLeft: '35px', marginRight: '20px' }}>
                      <OZInput type="password" name="password"
                        placeholder="Enter New Password" />
                    </div>
                    <span style={{ color: "#7A7A7A", fontSize: '12px', paddingLeft: '40px', paddingRight: '40px', paddingTop: '12px', opacity: '0.6' }}>Use a password at least 7 characters long with both letters and numbers.</span>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Typography sx={{
                        fontfamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: '#000000',
                        paddingBottom: '8px',
                        marginTop: '12px',
                        marginLeft: '20px',
                        marginRight: '20px'
                      }} flexGrow={1} alignSelf='center'>Confirm New Password</Typography>
                      {/* <TextField name="confirmpassword" type="password" size='small' placeholder='Enter New Password' sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '4px',
                          backgroundColor: '#ffff',
                          fontSize: '14px',
                          height: '44px',
                          marginRight: '20px',
                          marginTop: '6px',
                          marginBottom: '6px',
                          width: '310px',
                          marginLeft: '20px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #D6D6D7'
                        },
                        '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                          backgroundColor: '#D6D6D7',
                          border: '1px solid #D6D6D7'
                        }
                      }} /> */}
                      <div style={{ width: '310px', marginLeft: '20px', marginRight: '20px' }}>
                        <OZInput type="password" name="confirmpassword"
                          placeholder="Enter New Password" />
                      </div>
                      <DialogActions sx={{ marginTop: '40px', marginBottom: '24px', marginRight: '12px' }}>
                        <Button autoFocus variant='contained' sx={{ ...buttonStyle, fontWeight: 400 }} type='submit'
                          /* onClick={() => getChangePassword(changePassword)} */
                          form="admin-change-password-form2"
                        >
                          Reset Password
                        </Button>
                      </DialogActions>
                    </Grid>
                  </Grid>
                </form>
              )
            }
          </Formik>
        </div>
      </Dialog>}

    </div >
    )
  }

  const CallBackURLTextElement = (props, index) => {
    const [inputText, setInputText] = useState(userItems.callbackUrl)
    return (
      <div className='settingField' style={{ display: "flex", bgcolor: "#FFFFFF", justifyContent: 'space-between' }}>
        <Typography className='admin-settings-text-font' flexGrow={1} fontSize="14px">Callback Url</Typography>
        <div style={{ display: "flex" }}>
          {isEdit && (
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Button
                onClick={() => { setEditState(false) }}
                sx={{ ...SystemSettingsbuttonStyle, width: '48px', marginRight: "10px", marginTop: "5px" }}
              >
                Discard
              </Button>
              <Button
                type='submit'
                variant="contained"
                onClick={() => { updateSystemSettingsFields({ callbackUrl: inputText }) }}
                sx={{ ...SystemSettingsbuttonStyle, marginTop: "5px" }}

              >
                Save
              </Button>
            </div>
          )}
          <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
            {!isEdit && (
              <Button
                onClick={() => setEditState(true)}
                disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                sx={{
                  width: '15px',
                  height: '25px',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#008DFF',
                  marginTop: "2px",
                  paddingTop: "12px",
                  paddingBottom: "10px",
                  marginBottom: "5px",
                  marginRight: "10px"
                }}>
                Edit
              </Button>
            )}
          </div>
          <div className='admin-settings-input-text'>
            {!isEdit && <Typography sx={{ fontSize: '14px', wordBreak: "break-all", whiteSpace: "initial" }}>{userItems.callbackUrl}</Typography>}
          </div>
          {isEdit && <TextField name={'callbackUrl'} id={'callbackUrl'} onChange={(e) => setInputText(e.target.value)} size='small' value={inputText} type='text' sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '36px',
              marginRight: '24px',
              marginTop: '10px',
              marginBottom: '6px',
              width: '200px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              backgroundColor: '#D6D6D7',
              border: '1px solid #D6D6D7'
            }
          }} />}
        </div>
      </div>
    )
  }


  const DisplayCRMInSelectBoxElement = (props) => {
    return (<Formik
      initialValues={systemSettingFields}
      onSubmit={(data) => {
        data.screenPopMode = data.screenPopMode.screenPopMode
        updateSystemSettingsFields(data);
      }}>
      {(props) => (
        <form onSubmit={props.handleSubmit}
          id='agentForm'
          className='ozcwScrollbar'
        >
          <div style={{ display: "flex", bgcolor: "#FFFFFF", justifyContent: 'end' }} className='settingField'>
            <Typography className='admin-settings-text-font' fontSize={"14px"} flexGrow={1}>Display CRM in</Typography>
            <div style={{ display: "flex", }}>
              {isSystemSettingEdit && (
                <div style={{ display: "flex", }}>
                  <Button
                    onClick={() => setSystemSettingEditState(false)}
                    sx={{ ...SystemSettingsbuttonStyle, width: '48px', marginRight: "10px", marginTop: "15px" }}
                  >
                    Discard
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ ...SystemSettingsbuttonStyle, marginTop: "15px" }}
                  >
                    Save
                  </Button>
                </div>
              )}
              <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                  {!isSystemSettingEdit && (
                    <Button
                      onClick={() => setSystemSettingEditState(true)}
                      disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                      sx={{
                        width: '15px',
                        height: '25px',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#008DFF',
                        paddingTop: "11px",
                        paddingBottom: "10px",
                        marginBottom: "10px",
                        marginRight: "10px"
                      }}>
                      Edit
                    </Button>
                  )}
                </div>
              </div>
              <div className='admin-settings-input-text'>
                {console.log("userItems", userItems)}
                {console.log("selected valueesssssssss", userItems.screenPopMode?.CRMType)}
                {!isSystemSettingEdit && <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{userItems.screenPopMode?.CRMType}</Typography>}
              </div>
              {isSystemSettingEdit && <div style={{
                width: '200px', marginTop: '6px',
                marginRight: '20px',
              }}>
                <OZSelect
                  name={'screenPopMode'} sx={{
                    height: '44px', '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #D6D6D7',
                      borderRadius: '8px',
                      fontSize: '14px'
                    }
                  }}
                  placeholder={"Please Select"}
                  optionLabel="CRMType"
                  options={[{ screenPopMode: 1, CRMType: "Embed" }, { screenPopMode: 2, CRMType: "Popup" }, { screenPopMode: 3, CRMType: "Open in New Tab" }]}
                />
              </div>}
            </div>
          </div>
        </form>)}
    </Formik>)
  }

  const AdminCreationTextElement = ({ UserDetails, value }) => {
    const [inputText, setInputText] = useState(UserDetails.ParameterValue || UserDetails.DefaultValue);
    return (
      <div className='settingField' style={{ display: "flex", bgcolor: "#FFFFFF", justifyContent: 'space-between' }}>
        <Typography className='admin-settings-text-font' flexGrow={1} fontSize="14px">{UserDetails.ParameterName}</Typography>
        <div style={{ display: "flex" }}>
          {textEditModes[UserDetails.ParameterName] && (
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Button
                onClick={() => { handleEditTextField(UserDetails.ParameterName, false) }}
                sx={{ ...SystemSettingsbuttonStyle, width: '48px', marginRight: "10px", marginTop: "5px" }}
              >
                Discard
              </Button>
              <Button
                type='submit'
                variant="contained"
                onClick={(e) => { saveSettings(UserDetails, inputText) }}
                sx={{ ...SystemSettingsbuttonStyle, marginTop: "5px" }}
              >
                Save
              </Button>
            </div>
          )}
          <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
            <>
              {checkRoles("ROLE_ADMIN") &&
                <>
                  {!textEditModes[UserDetails.ParameterName] && (
                    <Button
                      onClick={() => handleEditTextField(UserDetails.ParameterName, true)}
                      disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                      sx={{
                        width: '15px',
                        height: '25px',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#008DFF',
                        marginTop: "2px",
                        paddingTop: "12px",
                        paddingBottom: "10px",
                        marginBottom: "5px",
                        marginRight: "10px"
                      }}>
                      Edit
                    </Button>
                  )}
                </>
              }
            </>
          </div>
          <div className='admin-settings-input-text'>
            {!textEditModes[UserDetails.ParameterName] && ((UserDetails.ParameterValue === null || UserDetails.ParameterValue === "") ? <Typography sx={{ fontSize: '14px', wordBreak: "break-all", whiteSpace: "initial" }}>{UserDetails.DefaultValue}</Typography> : <Typography sx={{ fontSize: '14px', wordBreak: "break-all", whiteSpace: "initial" }}>{UserDetails.ParameterValue}</Typography>)}
          </div>
          {textEditModes[UserDetails.ParameterName] && <TextField name={UserDetails.ParameterName} id={UserDetails.ParameterName} onChange={(e) => setInputText(e.target.value)} size='small' value={inputText} type='text' sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '36px',
              marginRight: '24px',
              marginTop: '10px',
              marginBottom: '6px',
              width: '200px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              backgroundColor: '#D6D6D7',
              border: '1px solid #D6D6D7'
            }
          }} />}
        </div>
      </div>
    )
  }

  const OZItem = (props) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={12} sx={{
        marginTop: '24px',
        marginRight: '24px'
      }}>

        <Typography height={'14px'} fontSize={'12px'} fontWeight={400} color={props.isDisabled ? '#000000' : '#7C878B'} lineHeight='14px' marginBottom='8px'>
          {props.label}
        </Typography>

        {
          props.isDisabled ?
            <OZInput name={props.id} disabled={props.id === 'apiKey'} /> :
            <Typography height={'16px'} fontSize={'14px'} fontWeight={400} color={'#000000'} lineHeight='16px' >
              {userItems[props.id]}
            </Typography>
        }

      </Grid >
    )
  }

  const onClickCancel = () => {
    setProfileEdit(false);
    window.location.reload();
  }

  return (
    <div>
      <Typography fontWeight='500' fontSize='18px' color='#212121' marginTop='25px' marginBottom='16px' >Edit User </Typography>
      <div style={{ display: 'flex', width: '757px', background: 'white', borderRadius: '8px', justifyItems: 'center', padding: '8px 32px 20px 32px', alignContent: 'space-between' }}>
        <div style={{ marginRight: '50px', marginTop: '24px' }}>
          {/*  <ProfilePic isEditMode={isProfileEdit} avatarName={userItems.fullName} /> */}
          {(userItems?.username) ?
            <Avatar style={{ width: '180px', height: '180px', fontWeight: 500, color: '#000000', fontSize: "40px" }} {...stringAvatar(userItems?.username)} ></Avatar>
            :
            null
          }
        </div>
        <Grid container direction="column">
          <Formik
            validationSchema={validationSchema}
            initialValues={initData}
            onSubmit={(data) => {
              updateAdminDetails(data)
            }}
          >
            {
              (props) => (
                <form onSubmit={props.handleSubmit} id='admin-settings' className='admin-settings'
                  style={{
                    marginLeft: '50px'
                  }}>

                  {!isProfileEdit && <OZItem id={'fullName'} label={'Name'} isDisabled={isProfileEdit} />}
                  <Stack direction={'row'} >
                    {isProfileEdit && <OZItem id="firstName" label={'First Name*'} isDisabled={isProfileEdit} />
                    }
                    {isProfileEdit && <OZItem id="lastName" label={' Last Name*'} isDisabled={isProfileEdit} />
                    }
                  </Stack>
                  {<OZItem id={'username'} label={'UserName'} isDisabled={isProfileEdit} />}
                  {<OZItem id={'email'} label={'Email'} isDisabled={isProfileEdit} />}
                  {<OZItem id={'apiKey'} label={'API Key'} isDisabled={isProfileEdit} />}
                  {<OZItem id={'phoneNumber'} label={'Phone Number'} isDisabled={isProfileEdit} />}

                  <Stack direction='row' spacing={4} mt={'24px'}>
                    <Button disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')} variant='outlined' sx={buttonStyle} onClick={() => { !isProfileEdit ? setDialog(true) : onClickCancel() }}>
                      {!isProfileEdit ? 'Change Password' : 'Cancel'}
                    </Button>
                    <Button disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')} type="submit" variant={!isProfileEdit ? 'outlined' : 'contained'} sx={buttonStyle} form='admin-settings'>
                      {!isProfileEdit ? 'Edit Profile' : 'Update Profile'}
                    </Button>
                  </Stack>
                </form>
              )
            }
          </Formik>
          {showDialog && <ChangePasswordAdmin open={showDialog} onClose={closeAdminEditForm} />}
        </Grid>
      </div >

      <Typography sx={SettingNameStyle}>System Settings</Typography>

      <Box sx={{ bgcolor: '#FFFFFF', marginTop: '32px', width: '757px', display: 'flex', borderRadius: '8px', flexDirection: 'column' }}>
        <DisplayCRMInSelectBoxElement />
        <CallBackURLTextElement />
      </Box >

      <Typography sx={SettingNameStyle}>Creation Limit <span style={{ opacity: '0.5', fontSize: '14px', fontWeight: 400 }}>(-1 signifies boundless possibilities for creation)</span></Typography>
      <Box sx={{ bgcolor: '#FFFFFF', marginTop: '32px', width: '757px', display: 'flex', borderRadius: '8px', flexDirection: 'column' }}>
        {UserDetails && UserDetails.map((item, index) => {
          return (
            <div key={index}>
              {item.InputType === "textfield" && <AdminCreationTextElement UserDetails={item} />}
            </div>
          )
        })}
      </Box >

      <Typography sx={SettingNameStyle}>Current Roles</Typography>
      <Box sx={{ bgcolor: '#FFFFFF', marginTop: '32px', width: '757px', borderRadius: '8px', flexDirection: 'column' }}>
        {getUserRoles()?.map((role) => {
          return (<Typography className='admin-settings-text-font' key={role.name}>{role.name}</Typography>)
        })}
      </Box>
    </div >
  );
}